import { makeAutoObservable } from "mobx";
import { getUiPrefRows } from "../../util/FilterUtil";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./ApplicationsConstant";
class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  version = 0;
  supervisor_ids = [];
  applicationStatus = "in[0,1,2,3,4,5,7]";
  constructor() {
    makeAutoObservable(this);
  }

  fetch = async (filterUrl) => {
    if (!this?.rowsPerPage) {
      const rows = await getUiPrefRows("form.grid")
      this.rowsPerPage = rows ?? 40
    }

    if (filterUrl || filterUrl == "") {
      this.filterOption = filterUrl;
    }

    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
    );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
  };

  edit = async function (data) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response.status === 200) {
      this.version += 1;
    }

  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    return response?.data?._id;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
    }
  };

  setSupervisorIds = async (ids) => {
    this.supervisor_ids = ids == null ? null : ids ?? []
  };


  setApplicationStatus = async (v) => {
    this.applicationStatus = v
  };


  bulkDelete = async (ids = []) => {
    const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
    if (response.status === 200) {
      this.fetch();
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);

  };

  resetPagination = () => {
    this.page = 1;
  }

}

const ApplicationsService = new Service();
export default ApplicationsService;

