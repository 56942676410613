import { BsQuestionSquareFill } from "react-icons/bs"
import { CiCalculator2 } from "react-icons/ci"
import { FaUser, FaUserCog } from "react-icons/fa"
import { FaGear } from "react-icons/fa6"
import { GrDocumentConfig } from "react-icons/gr"
import { HiOutlineClipboardDocumentList } from "react-icons/hi2"
import { MdDashboard, MdOutlineMarkEmailUnread, MdPersonSearch } from "react-icons/md"
import { RiUserStarFill, RiFileWarningFill } from "react-icons/ri"

export const menuItems = [


    {
        items: [
            {
                path: "/applications",
                icon: "uil-flask",
                name: "Applications",
                logo: MdDashboard,
                right: 2800
            },
            {
                path: "/accounts",
                icon: "uil-flask",
                name: "Accounts",
                logo: MdPersonSearch,
                right: 2700
            },
            {
                path: "/#",
                name: "Configurations",
                logo: FaGear,
                subItems: [
                    {
                        path: "/role",
                        icon: "uil-flask",
                        name: "User Role",
                        logo: FaUserCog,
                        right: 2200
                    },
                    {
                        path: "/user",
                        icon: "uil-flask",
                        name: "Users",
                        logo: FaUser,
                        right: 2300
                    },

                    {
                        path: "/questions",
                        name: "Questions",
                        logo: BsQuestionSquareFill,
                        right: 2400
                    },
                    // {
                    //     path: "/reports",
                    //     name: "Reports",
                    //     logo: BiSolidReport,
                    // },
                    {
                        path: "/risk-calculation",
                        name: "Risk Calculation",
                        logo: CiCalculator2,
                        right: 2500
                    },
                    {
                        path: "/email-logs",
                        name: "Email Logs",
                        logo: MdOutlineMarkEmailUnread,
                        right: 1000
                    },
                    // {
                    //     path: "/doc-category",
                    //     name: "Document Categories",
                    //     logo: GrDocumentConfig,
                    //     right: 2600
                    // },
                    {
                        path: "/disclosures",
                        name: "Disclosures",
                        logo: HiOutlineClipboardDocumentList,
                        right : 2100
                    },

                    {
                        path: "/doc-type",
                        name: "Document Types",
                        logo: GrDocumentConfig,
                        right: 2900
                    },
                    {
                        path: "/introducer",
                        name: "Introducer",
                        logo: RiUserStarFill, 
                        right: 4000, 
                    },
                    {
                        path: "/reason-code",
                        name: "Reason Code",
                        logo: RiFileWarningFill,
                        right: 3000,
                    },
                ]
            }
        ]
    },
]
